import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { ChevronRight } from "@material-ui/icons";
import _ from "lodash";

export default function SubmissionSummaryChart(props) {
  const rowData = props.row.original;
  const [selected, setSelected] = React.useState(0);

  const items = ["Conforming Claims", "Non-Conforming Claims", "Invalid 340B ID", "Not A Contract Pharmacy"];
  const colors = ["#24B668", "#28D0FC", "#3246D3", "#FF681F"];
  const percentage = [
    rowData.conforming.count,
    rowData.non_conforming.count,
    rowData.invalid_entities.count,
    rowData.invalid_pharmacies.count,
  ];

  const getIndicatorName = (index) => {
    return ["green", "skyblue", "blue", "orange"][index];
  };

  const renderTable = () => {
    switch (items[selected]) {
      case "Not A Contract Pharmacy":
        return renderInvalidPharmaciesTable(rowData.invalid_pharmacies.details);
      case "Invalid 340B ID":
        return renderInvalidEntitiesTable(rowData.invalid_entities.details);
      case "Non-Conforming Claims":
        return renderNonConformingPharmaciesTable(rowData.non_conforming.details);
      case "Conforming Claims":
        return renderConformingPharmaciesTable(rowData.conforming.details);
    }
  };

  const renderInvalidPharmaciesTable = (items) => {
    return _.map(items, (val, key) => {
      return (
        <tr key={key} className="sm">
          <td>{key}</td>
          <td>--</td>
          <td>{val}</td>
        </tr>
      );
    });
  };

  const renderInvalidEntitiesTable = (items) => {
    return _.map(items, (val, key) => {
      return (
        <tr key={key} className="sm">
          <td>{key}</td>
          <td>--</td>
          <td>{val}</td>
        </tr>
      );
    });
  };

  const renderNonConformingPharmaciesTable = (items) => {
    return _.map(items, (val, key) => {
      return _.map(val, (v, k) => {
        return (
          <tr key={`${key}-${k}`} className="sm">
            <td>{key}</td>
            <td>{k}</td>
            <td>{v}</td>
          </tr>
        );
      });
    });
  };

  const renderConformingPharmaciesTable = (items) => {
    return _.map(items, (val, key) => {
      return _.map(val, (v, k) => {
        return (
          <tr key={`${key}-${k}`} className="sm">
            <td>{key}</td>
            <td>{k}</td>
            <td>{v}</td>
          </tr>
        );
      });
    });
  };

  const renderChevron = (index) => {
    if (selected === index) {
      return <ChevronRight style={{ fontSize: 20 }} />;
    }
  };

  const renderItem = () => {
    return _.map(items, (item, index) => {
      return (
        <div
          key={index}
          className={
            items[selected] === item
              ? "chart__component__submission__summary__info__item chart__component__submission__summary__info__item--active"
              : "chart__component__submission__summary__info__item"
          }
          onClick={() => setSelected(index)}
        >
          <div
            className={`chart__component__submission__summary__info__item__indicator chart__component__submission__summary__info__item__indicator--${getIndicatorName(
              index
            )}`}
          ></div>
          <div className="chart__component__submission__summary__info__item__title">{`${items[index]}`}</div>
          <div className="chart__component__submission__summary__info__item__icon">{renderChevron(index)}</div>
        </div>
      );
    });
  };

  const renderPieChart = () => {
    return (
      <PieChart
        lineWidth={16}
        radius={47}
        viewBoxSize={[105, 105]}
        animate={true}
        startAngle={-135}
        paddingAngle={2}
        segmentsShift={(index) => (index === selected ? 3 : 0)}
        data={[
          { title: "Conforming", value: rowData.conforming.count, color: colors[0] },
          { title: "Non Conforming", value: rowData.non_conforming.count, color: colors[1] },
          { title: "Invalid CEs", value: rowData.invalid_entities.count, color: colors[2] },
          { title: "Invalid CPs", value: rowData.invalid_pharmacies.count, color: colors[3] },
        ]}
      />
    );
  };

  return (
    <div className="row">
      <div className="col-3 d-none d-xl-block">
        <div className="row" style={{ height: "100%" }}>
          <div className="col-12 d-flex flex-column justify-content-between" style={{ paddingRight: 0 }}>
            <div className="stat_card__container">
              <div className="stat_card__header">Manufacturers</div>
              <div className="stat_card__body">
                <div className="stat_card__body__value">{rowData.manufacturers}</div>
              </div>
            </div>
            <div className="stat_card__container">
              <div className="stat_card__header">Contract Pharmacies</div>
              <div className="stat_card__body">
                <div className="stat_card__body__value">{rowData.pharmacies}</div>
              </div>
            </div>
            <div className="stat_card__container">
              <div className="stat_card__header">Unique NDCs</div>
              <div className="stat_card__body">
                <div className="stat_card__body__value">{rowData.ndcs}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-9">
        <div className="chart__component__submission__summary__container">
          <div className="chart__component__submission__summary__info">
            <div className="chart__component__submission__summary__info__header">Submission Summary</div>
            <div className="chart__component__submission__summary__info__chart">
              {renderPieChart()}
              <div className="chart__component__submission__summary__info__chart__label">{`${(
                (percentage[selected] / _.sum(percentage)) *
                100
              ).toFixed(1)}%`}</div>
            </div>
            <div className="chart__component__submission__summary__info__item__container">{renderItem()}</div>
          </div>
          <div className="chart__component__submission__summary__details">
            <table className="secondary">
              <thead>
                <tr className="sm">
                  <th>Mfr</th>
                  <th>Pharmacy Chain</th>
                  <th>Claims</th>
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
