import React from "react";

const TermsOfUse = () => {
  return (
    <div className="designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--scroll stat-cards__confirmation stat-cards__confirmation--disabled">
      NON-IMiD, CAMZYOS<sup>®</sup> and KRAZATI<sup>®</sup> DISTRIBUTION THROUGH THIRD-PARTY PHARMACY SUBMITTED DATA TERMS OF USE
      <ol>
        <li>
          Enrollment in 340B ESP™. A 340B covered entity “Purchaser” that seeks to acquire a non-immunomodulatory (Non-IMiD”), Camzyos<sup>®</sup> or Krazati<sup>®</sup> covered outpatient drug subject to Bristol-Myers Squibb's (“BMS”) offer of 340B pricing (“340B Drug”) through a third-party pharmacy must enroll in 340B ESP™ and submit the specified BMS Claims Data (as defined below) through the Covered Entity Portal.
        </li>
        <li>
          Terms of Use. Purchaser that submits BMS Claims Data to the 340B ESP™ platform must agree to the 340B ESP™ Terms of Use (“Platform Terms”) as well as the additional Terms of Use included herein (“BMS Terms”) (collectively “Combined Terms”). Specifically, the Combined Terms apply when Purchaser elects to have a 340B Drug dispensed through a third-party pharmacy that Purchaser hereby attests is registered for such use in the Office of Pharmacy Affairs Information System (“OPAIS”), is the Purchaser's legally authorized agent at all relevant times to time of dispense for the 340B Drug, and is validly designated with BMS pursuant to BMS's 340B practice in effect at the time of dispense, including attestation that Purchaser does not have a Purchaser-owned pharmacy capable of dispensing the applicable 340B Drug (“Contract Pharmacy”). For avoidance of doubt, the Platform Terms extend and apply to any additional data and uses of data required by the BMS Terms.
        </li>
        <li>
          Prevailing Terms. These Terms are intended to be consistent with the Platform Terms, available at 340besp.com/terms-of-use. If these Terms are found to be inconsistent with any provisions of the Platform Terms, these Terms will prevail over the Platform Terms with respect to the inconsistent provisions only. All other terms and conditions of the Platform Terms shall remain in full force and effect and are hereby ratified and confirmed by Purchaser.
        </li>
        <li>
          340B Purchase Integrity. With every Contract Pharmacy designation and BMS Claims Data submission to the 340B ESP™ platform, Purchaser attests that it remains responsible for and maintains title to the 340B Drug during all points from Purchaser's acquisition until dispensing to Purchaser's eligible patient, and that Purchaser ensures compliance with all legal requirements, including the federal statutory diversion and duplicate discount prohibitions (42 U.S.C. § 256b(a)(5)(A)-(B)) and any other applicable requirements such as the Drug Supply Chain Security Act and state distribution and wholesaler licensing restrictions. Purchaser attests to shipment of the 340B Drug only to the same appropriately designated location of actual dispense of the 340B Drug and submission of data reflecting only such shipment, and acknowledges that any drug product subject to other, non-transparent arrangements affecting BMS's offer of 340B pricing (for example, but not limited to, “banking” orders outside of the reasonable window below, virtual return, alternative distribution, virtual credit) falls outside these BMS Terms, may not qualify as a 340B Drug, and may result in chargeback reversal or other action.
        </li>
        <li>
          Data Submission. Purchaser that designates Contract Pharmacy will submit to the 340B ESP™ platform BMS Claims Data (Section 7 below) beginning no later than 10 to 15 days after the Contract Pharmacy designation process is complete (“Designation Effective Date”). Purchaser must communicate to BMS all BMS Claims Data (see Section 7) within 45 days of dispense to the patient. Acknowledging that the U.S. Department of Health and Human Services (“HHS”) prohibits reclassifying a non-340B purchase as 340B-eligible after the fact, Purchaser attests that it will only request or otherwise seek 340B pricing at the time of the original purchase. Therefore, all order information should be submitted within 45 days of the date of dispense to the patient and should correspond fully with such dispense. Purchaser further attests that all BMS Claims Data will be accurate and complete, and that Purchaser will notify BMS promptly upon the discovery of any inaccuracy or lack of completed BMS Claims Data in Purchaser's submission. Purchaser acknowledges that data submissions that do not meet the above criteria may result in chargeback reversal or other action.
        </li>
        <li>
          Confidentiality. Purchaser will use the 340B ESP™ platform to ensure transmission confidentiality. BMS and its technology platform provider will use commercially reasonable security measures to protect BMS Claims Data received pursuant to these Combined Terms. BMS and its technology platform provider will maintain in confidence and not disclose to third parties any non-public information it may receive as a result of the submission of claims-level data by Purchaser, except as may be required by law or these Combined Terms. BMS and its technology platform provider may disclose the BMS Claims Data received under these Combined Terms to contractors performing services to BMS, as long as such contractors are under obligations of confidentiality similar to those in these Combined Terms.
        </li>
        <li>
          Claims-Level Data. Purchaser will provide the following data elements with each 340B Drug order, as reflected in the Combined Terms applicable at the time of dispense, which include but are not limited to those specified in the Platform Terms and as required by the BMS Terms (collectively referred to as “BMS Claims Data”):
          <ul>
            <li>Contracted Entity ID/340B ID Number</li>
            <li>Date of Service/Date of Dispense</li>
            <li>Date Prescribed</li>
            <li>NDC-11</li>
            <li>Quantity</li>
            <li>Rx Number</li>
            <li>Service Provider ID</li>
            <li>Service Provider ID Qualifier</li>
            <li>Prescriber ID</li>
            <li>Prescriber ID Qualifier</li>
            <li>Payer BIN</li>
            <li>PCN</li>
            <li>Wholesaler Invoice Number (optional)</li>
            <li>Ship to Date (optional)</li>
            <li>Ship to Location (optional)</li>
            <li>340B Account Number (optional)</li>
            <li>Product Serialization number (optional)</li>
          </ul>
        </li>
        <li>
          Data Use. BMS requests and will use BMS Claims Data for the purpose of supporting 340B program integrity. BMS will review BMS Claims Data to ensure that purchases comply with applicable law, including the statutory diversion and duplicate discount prohibitions; that only one 340B discount is paid on a single transaction; and that purchases are otherwise consistent with BMS's offer to Purchaser, including consistency with BMS's distribution practices and these BMS Terms. Purchaser represents and warrants that Purchaser has all necessary rights, title license and authority (including all necessary permissions from third party owners and rights holders) to provide BMS Claims Data as set forth herein. Purchaser grants BMS a worldwide, sublicensable, non-exclusive, royalty-free, perpetual, irrevocable license to collect, process, disclose, create derivative works for and otherwise use the BMS Claims Data for the purposes set forth in the Combined Terms.
        </li>
        <li>
          BMS reserves the right to alter these Terms at any time and may do so as necessary to safeguard 340B program integrity.
        </li>
      </ol>
      BY CLICKING THE “ACCEPT” BUTTON, OR OTHERWISE AFFIRMING ACCEPTANCE YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS; (B) REPRESENT THAT YOU HAVE THE AUTHORITY TO ACCEPT THESE TERMS ON BEHALF OF THE COVERED ENTITY BY WHOM YOU ARE EMPLOYED OR ACTING AS AN AGENT TO PROVIDE COVERED ENTITY CLAIMS DATA, OR ON BEHALF OF YOUR EMPLOYER OR PRINCIPAL IF A LEGAL ENTITY OTHER THAN THE COVERED ENTITY, AND THAT ACCEPTANCE OF THESE TERMS HAVE BEEN DULY AUTHORIZED BY THE COVERED ENTITY AND YOUR EMPLOYER; AND (C) YOU ACCEPT THESE TERMS AND AGREE THAT YOU, THE COVERED ENTITY, AND YOUR EMPLOYER OR PRINCIPAL ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS OR DO NOT HAVE SUCH AUTHORITY, DO NOT USE THE COVERED ENTITY PLATFORM.
      <br />
      <br />
      QUESTIONS: Email: BMS340B@bms.com
    </div>
  )
}

export default TermsOfUse;
